* {
  box-sizing: border-box;
  color: var(--text-color);
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

.layout-wrapper {
  min-height: 100vh;
}

.p-button > span {
  color: #fff;
}

.p-button-link > span {
  color: var(--primary-color);
  text-decoration: underline;
}

.p-button-outlined > span {
  color: var(--primary-color);
}

.p-sidebar .p-sidebar-content {
  padding: 1.5rem;
}

.p-avatar > span {
  color: #fff;
}
