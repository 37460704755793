.layout-sidebar {
  position: fixed;
  width: 80px;
  height: 100%;
  z-index: 999;
  top: 72px;
  background-color: #002f43;
  border-top-right-radius: 15px;
}

.layout-menuitem-text {
  font-weight: 400;
  margin-left: 1.25rem;
  margin-right: 0.5rem;
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: 0.857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-900);
      margin: 0.75rem 0;
    }

    > a {
      display: none;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  .layout-menuitem-disabled {
    pointer-events: none;
    cursor: default;
    background-color: var(--surface-ground) !important;
    border: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      background-color: var(--surface-ground);
      color: var(--text-color);
      cursor: pointer;
      padding: 10px 16px;
      text-decoration: none !important;
      transition: background-color $transitionDuration,
        box-shadow $transitionDuration;

      i {
        font-size: var(--icon-size);
      }

      .layout-menuitem-icon {
        margin-right: 1rem;
        font-size: 21px;
      }

      .layout-menuitem-tag {
        background: #f2eeee;
        color: var(--text-color);
        border-radius: 0.5rem;
        font-size: 8px;
        padding: 2px 5px;
        text-transform: uppercase;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.active-route > i {
        color: var(--primary-color);
      }

      &.active-route > span {
        color: var(--primary-color);
      }

      &.active-route > span.layout-menuitem-tag {
        color: #fff;
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;

      li {
        // uncomment to add indentation to sub-menu items
        // a {
        //   padding-left: 2rem;
        // }

        li {
          a {
            margin-left: 2rem;
          }

          li {
            a {
              margin-left: 2.5rem;
            }

            li {
              a {
                margin-left: 3rem;
              }

              li {
                a {
                  margin-left: 3.5rem;
                }

                li {
                  a {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu-root {
  background-color: #fff !important;
  border-bottom: 1px solid #ededed;
}
