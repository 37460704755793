/* You can add global styles to this file, and also import other style files */

//for primeflex grid system
$gutter: 0.5rem;
@import "assets/layout/styles/layout/variables";
@import "assets/layout/styles/layout/mixins";
@import "assets/layout/styles/layout/preloading";
@import "assets/layout/styles/layout/main";
@import "assets/layout/styles/layout/topbar";
@import "assets/layout/styles/layout/menu";
@import "assets/layout/styles/layout/config";
@import "assets/layout/styles/layout/content";
@import "assets/layout/styles/layout/footer";
@import "assets/layout/styles/layout/responsive";
@import "assets/layout/styles/layout/utils";
@import "assets/layout/styles/layout/typography";

/* PrimeNG */
@import "../node_modules/primeflex/primeflex";

@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}

// amasol styling theme
@import "./assets/layout/styles/theme/amasol-theme";

.cell-span {
  border-bottom: 1px solid #d9dcde !important;
  border-right: 1px solid #d9dcde !important;
  border-left: 1px solid #d9dcde !important;
}

.cell-group {
  background: white !important;
}

.cont-span {
  color: white !important;
}

.contract-tabel {
  .ag-select-list {
    top: -22px !important;
  }

  .ag-paging-panel {
    position: absolute !important;
    top: -45px !important;
    right: 0 !important;
    border-top: none !important;
  }

  .ag-root-wrapper {
    overflow: unset !important;
  }

  .ag-paging-row-summary-panel {
    .ag-paging-row-summary-panel-number {
      font-weight: bold;
    }
  }

  .ag-paging-description {
    .ag-paging-number {
      font-weight: bold;
    }
  }

  .ag-paging-panel > .ag-paging-page-size .ag-wrapper {
    min-width: 70px !important;
  }
}

.contrcat-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  line-height: 8px;
}

// gojs license
.gshDisplay {
  border-color: #c8b999;
  opacity: 0.19;
  margin-bottom: 8px;
}
