// variables
:root {
  --font-family: opensans;
  --text-color: #6c6c6c;
  --icon-color: #6c6c6c;
  --primary-color: #0074a4;
  --primary-color-text: #0074a4;
  --warning-color: #f5980c;
  --warning-bg: #fff9ef;
  --danger-color: #a64b38;
  --danger-bg: #fff5f4;
  --success-color: #87b140;
  --success-bg: #f4fff3;
  --icon-size: 16px;
  --surface-ground: #fff;
  --sidebar-header: #e9e9e9;
  --border-color: #dee2e6;
}

.primary-text {
  color: var(--primary-color-text);
}

.warning-chip {
  background-color: var(--warning-bg);
  color: var(--warning-color);
  border-radius: 16px;
  padding: 0.5rem;
  width: fit-content;
}

.danger-chip {
  background-color: var(--danger-bg);
  color: var(--danger-color);
  border-radius: 16px;
  padding: 0.5rem;
  width: fit-content;
}

.success-chip {
  background-color: var(--success-bg);
  color: var(--success-color);
  border-radius: 16px;
  padding: 0.5rem;
  width: fit-content;
}

.error-block {
  background-color: var(--danger-bg);
  border-radius: 12px;
  padding: 0.5rem;

  li {
    color: var(--danger-color);
  }
}

.p-component,
.p-link,
.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input,
.p-chips .p-chips-multiple-container .p-chips-input-token input,
.p-inputtext,
.p-terminal .p-terminal-input {
  font-family: var(--font-family);
}

.p-checkbox .p-checkbox-box {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.tree-folder-icon {
  color: var(--icon-color);
  font-size: var(--icon-size);
}

.p-card .p-card-content {
  padding: 0;
}

.icon-size-color {
  color: var(--icon-color);
  font-size: var(--icon-size);
}

::before {
  box-sizing: content-box;
}

.p-link:disabled {
  opacity: 0.3;
}

.material-symbols-outlined {
  font-size: 18px;
}

// remove background from clear filter button
.p-datatable,
.p-datatable.p-datatable-sm {
  .p-datatable-header {
    background: none;
    border: none;
    padding: 0.5rem 0; // minimize padding from clear filter button
  }
}

.p-button.p-button-outlined {
  background: #fff;
}

.p-button.p-button-outlined:disabled {
  opacity: 0.3;
}

.p-button.secondary-btn,
.p-button.secondary-btn:hover,
.p-link.secondary-btn,
.p-link.secondary-btn:hover {
  background: #fff !important;
  border: 1px solid var(--primary-color);

  span {
    color: var(--primary-color);
  }
}

.p-link.delete-btn,
.p-link.delete-btn:hover {
  background-color: #fff;
  border: 1px solid var(--danger-color);

  span {
    color: var(--danger-color);
  }
}

.p-tree .p-tree-container .p-treenode {
  .p-treenode-label {
    width: 100%;
  }

  .p-treenode-content.p-highlight {
    i,
    h6,
    p {
      color: var(--primary-color);
    }

    img.svg-img {
      filter: invert(0.5) sepia(1) saturate(60) hue-rotate(175deg);
    }
  }

  .p-treenode-content .p-tree-toggler {
    display: none;
  }

  .p-treenode-content:not(.p-treenode-selectable) {
    cursor: not-allowed;
  }
}

.p-sidebar-content {
  height: 100%;
  padding: 0 !important;
  overflow-x: hidden;
}

.p-sidebar .p-sidebar-header {
  padding: 0;
  z-index: 999;

  .p-sidebar-close {
    position: absolute;
    right: 5px;
    top: 10px;
    margin-right: 1rem;
  }
}

.p-sidebar.right-sidebar-view {
  &.width-60 {
    width: 60% !important;
  }

  &.width-75 {
    width: 67% !important;
  }
}

.right-sidebar-header {
  position: sticky;
  top: 0;
  background: #f9f9f9; // update the sibebar header background to var(--sidebar-header)
  width: 100%;
  z-index: 1;
  padding-top: 1.5rem;
}

// to add sticky footer
.right-sidebar-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 10;
  border-top: 1px solid var(--border-color, #dee2e6);
  padding: 8px 20px;
  height: 68px;
  display: flex;
  align-items: center;
}

//
.p-datatable-scrollable > .p-datatable-wrapper {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-radius: 5px;
}

// reports icon
.icon-class {
  height: 12px;
}

.height-40 {
  height: 40px;
}

.header-color {
  background: #f9f9f9;
}

/* Ag grid toggle button */

.ag-toggle-button-input-wrapper.ag-checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ag-toggle-button-input-wrapper.ag-checked::before {
  border-color: var(--primary-color);
}

/* Override checkbox style in ag-Grid with Material theme */

.ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--primary-color);
}

/* setting height of ag-grid table and p-tree */
.custom-ag-table {
  height: calc(100vh - 195px);
  width: 100%;
  z-index: 0;
  position: relative;
}

.tree-height {
  max-height: calc(100vh - 195px);
  overflow: auto;
}
