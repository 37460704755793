.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 110px 25px 0 105px;
  transition: margin-left $transitionDuration;
}

.layout-pad-top {
  padding-top: 72px !important;
}

.layout-main {
  flex: 1 1 auto;
  overflow: hidden;
}
