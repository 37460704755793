.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 10px;
    z-index: 3000;
  }
}
